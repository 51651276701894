import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Teachers } from "../../about-types"
import { TeacherView } from "../teaching-fellowship/teacher"
import { Person } from "./person"
import styles from "./people-list.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface PeopleListProps extends Teachers {
  small?: boolean
  htmlTitle?: boolean
  disableLinks?: boolean
}

export const PeopleList: FunctionComponent<PeopleListProps> = ({
  title,
  teachers,
  htmlTitle = false,
  small = false,
  disableLinks
}) => (
  <div className={styles.peopleList}>
    <div className={classNames(styles.content, small && styles.small)}>
      {htmlTitle ? (
        <Markdown className={styles.title} content={title} />
      ) : (
        <div className={styles.title}>{title}</div>
      )}
      {small ? (
        <div className={styles.people}>
          {teachers.map((teacher) => {
            return (
              <Person
                teacher={teacher}
                key={teacher.name}
                htmlTitle={htmlTitle}
              />
            )
          })}
        </div>
      ) : (
        <div className={styles.peopleGrid}>
          {teachers.map((teacher) => {
            return (
              <TeacherView
                className={styles.teacherCard}
                teacher={teacher}
                key={teacher.name}
                htmlTitle={htmlTitle}
                disableLink={disableLinks}
              />
            )
          })}
        </div>
      )}
    </div>
  </div>
)
