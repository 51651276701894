import React, { FunctionComponent } from "react"
import { Carousel } from "../../../../components/carousel/carousel"
import { ProductDetails } from "../../../store/store-types"
import styles from "./founder.module.scss"
import { SimpleProduct } from "@features/about/SimpleProduct/simple-product"

export interface FounderProductsProps {
  items: ProductDetails[]
}

export const FounderProducts: FunctionComponent<FounderProductsProps> = ({
  items
}) => {
  return (
    <Carousel className={styles.carousel}>
      {items?.map((item) => {
        return (
          <SimpleProduct
            product={item}
            key={item?.link}
            url={item?.link}
            className={styles.product}
          />
        )
      })}
    </Carousel>
  )
}
