import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
  ComponentType
} from "react"
import classNames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"
import { IndexPageKey } from "../../types/page"
import { Resource, ResourceType } from "../../types/resource"
import { Carousel } from "../carousel/carousel"
import { CardResource } from "../card/card-resource"
import { CardResourceSkeleton } from "../card/card-resource-skeleton"
import { fetchFeaturedResources } from "./helpers"
import styles from "./featured-resources.module.scss"

export const REQUIRED_LIST_LENGTH = 4

export type FeaturedResourcesLabelVariant = "light" | "dark"

export interface ResourceWithCustomComponent extends Resource {
  component?: ComponentType
}

export type ListItem = ResourceWithCustomComponent | null

export interface FeaturedResourcesProps {
  className?: string
  shouldUpdateList?: boolean
  labelCollapse?: boolean
  labelVariant?: FeaturedResourcesLabelVariant
  labelId?: string
  list: ListItem[]
  type?: ResourceType
  pageKey?: IndexPageKey
  showResourceTypes?: boolean
  displayDate?: boolean
  bypassRequiredListLength?: boolean
}

const initList = Array(REQUIRED_LIST_LENGTH).fill(null)

export const FeaturedResources: FunctionComponent<FeaturedResourcesProps> = ({
  className,
  shouldUpdateList = false,
  labelCollapse,
  labelVariant = "dark",
  labelId,
  list,
  pageKey,
  showResourceTypes,
  type,
  displayDate = false,
  bypassRequiredListLength
}) => {
  const [dynamicList, setDynamicList] = useState<ListItem[]>(initList)
  const { formatDate } = useIntl()

  const fetchDynamicList = useCallback(async (): Promise<void> => {
    const isIE11 = "msCrypto" in window

    // "contentful" library doesn't support IE11, so don't try to fetch latest data on IE11
    if (pageKey && !isIE11) {
      const newList = await fetchFeaturedResources(pageKey)

      if (newList.length === REQUIRED_LIST_LENGTH) {
        setDynamicList(newList)

        return
      }
    }

    setDynamicList(list)
  }, [list, pageKey])

  useEffect(() => {
    if (shouldUpdateList) {
      fetchDynamicList()
    }
  }, [fetchDynamicList, shouldUpdateList])

  const listLengthValid =
    dynamicList &&
    (dynamicList.length >= REQUIRED_LIST_LENGTH || bypassRequiredListLength)

  if (shouldUpdateList && !listLengthValid) {
    return null
  }

  const listToUse = shouldUpdateList ? dynamicList : list

  const listToRender = [...listToUse]
    .sort((a, b) => {
      // Leave order unchanged if items have no datePublished field
      // Note that we MUST return 0, as returning 1 or -1 can have
      // opposite results from browser to browser (e.g. Firefox seems
      // to iterate in the opposite order from Chrome, so a and b will
      // be reversed)
      if (!a?.datePublished || !b?.datePublished) {
        return 0
      }

      // Otherwise, sort by datePublished descending
      return b?.datePublished - a?.datePublished
    })
    .slice(0, REQUIRED_LIST_LENGTH)

  return (
    <section className={classNames(styles.featuredResources, className)}>
      {labelId && (
        <div
          className={classNames(
            styles.label,
            styles[labelVariant],
            labelCollapse && styles.collapse
          )}
        >
          <FormattedMessage id={labelId} />
        </div>
      )}
      <Carousel className={styles.carousel}>
        {listToRender.map((card, index) => {
          if (!card) {
            return (
              <CardResourceSkeleton key={`card-resource-skeleton-${index}`} />
            )
          }

          const { component } = card as ResourceWithCustomComponent
          const CardComponent = component || CardResource

          return displayDate && card.datePublished ? (
            <div key={card.title}>
              <CardComponent {...card} type={type || card.type} titleTag="h2" />
              <span className={styles.resourceType}>
                {formatDate(card.datePublished)}
              </span>
            </div>
          ) : showResourceTypes && card.type ? (
            <div key={card.title}>
              <CardComponent {...card} type={type || card.type} titleTag="h2" />
              <span className={styles.resourceType}>
                <FormattedMessage id={`${labelId}-${card.type}`} />
              </span>
            </div>
          ) : (
            <CardComponent
              key={card.title}
              {...card}
              type={type || card.type}
              titleTag="h2"
            />
          )
        })}
      </Carousel>
    </section>
  )
}
