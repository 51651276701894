import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { WhoWeArePageQuery, WhoWeAreFragment } from "../../../graphql/types"
import { PageProps } from "../../types/page"
import { Page } from "@components/page/page"
import { WhoWeArePage } from "@features/about/components/who-we-are-page/who-we-are-page"
import { PageBreadcrumbs } from "@core/breadcrumbs"
import { PathPrefix } from "@core/constants"
import { WhoWeAreDetails } from "@features/about/about-types"
import { mapAboutWhoAreWePageToView } from "@features/about/mappers/about-who-are-we-page-mapper"

export const WhoWeAreTemplate: FunctionComponent<
  PageProps<WhoWeArePageQuery>
> = ({ data, ...props }) => {
  const allContentfulTeacher = data?.allContentfulTeacher

  const details: WhoWeAreDetails = mapAboutWhoAreWePageToView(
    data?.contentfulAboutWhoWeAre as WhoWeAreFragment
  )

  const teachingFellowship = (allContentfulTeacher?.nodes || []).map(
    (teacher: any) => ({
      name: `${teacher?.shortName}`,
      image: teacher?.image?.fluid?.src || "",
      bio: teacher?.shortBio?.childMarkdownRemark?.rawMarkdownBody || "",
      url: `${PathPrefix.Teachers}/${teacher?.slug}`
    })
  )

  return (
    <Page {...props} pageOptions={{ breadcrumbs: PageBreadcrumbs.WhoWeAre }}>
      <WhoWeArePage details={details} teachingFellowship={teachingFellowship} />
    </Page>
  )
}

export default WhoWeAreTemplate

export const query = graphql`
  query WhoWeArePage {
    contentfulAboutWhoWeAre {
      ...WhoWeAre
    }
    allContentfulTeacher(
      filter: {
        ligonierTeachingFellow: { eq: true }
        contentful_id: { ne: "teacher_5" }
      }
      sort: { fields: [lastName, firstName] }
    ) {
      nodes {
        lastName
        firstName
        shortName
        shortBio {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        image {
          fluid {
            src
          }
        }
        slug
      }
    }
  }
`
