import {
  WhoWeAreFragment,
  AboutTeacherFragment
} from "../../../../graphql/types"
import { PathPrefix } from "../../../core/constants"
import { WhoWeAreDetails, Teacher } from "../about-types"

const mapTeacher = (teacher: AboutTeacherFragment | null): Teacher | null => {
  if (!teacher) {
    return null
  }

  return {
    name: `${teacher.shortName}`,
    bio:
      teacher?.executiveBio?.childMarkdownRemark?.rawMarkdownBody ||
      teacher.shortBio?.childMarkdownRemark?.rawMarkdownBody ||
      "",
    image: teacher.image?.fluid?.src || "",
    url: `${PathPrefix.Teachers}/${teacher.slug}`
  }
}

export const mapAboutWhoAreWePageToView = (
  data: WhoWeAreFragment
): WhoWeAreDetails => {
  return {
    header: {
      title: data.headerTitle || "",
      subtitle: data.headerSubTitle || ""
    },
    founder: {
      name: data.founderBlock?.title?.childMarkdownRemark?.html || "",
      image: data.founderBlock?.image?.fluid?.src || "",
      info: data.founderBlock?.description?.childMarkdownRemark?.html || "",
      url: `${PathPrefix.Teachers}/rc-sproul`,
      products: undefined,
      resources: undefined
    },
    teachingFellowship: {
      title: "Teaching Fellowship"
    },
    leadership: {
      title: data.leadershipTitle?.childMarkdownRemark?.rawMarkdownBody || "",
      teachers: (data.leadershipTeam || [])
        .map(mapTeacher)
        .filter((teacher: any) => teacher) as Teacher[]
    },
    directors: {
      title: "Board of Directors",
      teachers: (data.boardOfDirectors || []).map((block: any) => ({
        name: block?.title?.childMarkdownRemark?.rawMarkdownBody || "",
        bio: block?.description?.childMarkdownRemark?.rawMarkdownBody || "",
        image: block?.image?.fluid?.src || "",
        url: ""
      }))
    },
    ourHistory: data.ourHistory?.childMarkdownRemark?.rawMarkdownBody || "",
    promo: {
      title:
        data.promotionalBlock?.title?.childMarkdownRemark?.rawMarkdownBody ||
        "",
      body:
        data.promotionalBlock?.description?.childMarkdownRemark
          ?.rawMarkdownBody || "",
      image: data.promotionalBlock?.image?.fluid?.src || "",
      url: data.promotionalBlock?.urlLink || ""
    }
  }
}
