import React, { FunctionComponent } from "react"
import { Button } from "../../../../components/button/button"
import styles from "./promo-block.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface PromoBlockProps {
  image: string
  title: string
  body: string
  buttonText: string
  buttonUrl: string
}

export const PromoBlock: FunctionComponent<PromoBlockProps> = ({
  title,
  image,
  body,
  buttonText,
  buttonUrl
}) => (
  <div className={styles.promoBlock}>
    <div className={styles.content}>
      <div
        className={styles.image}
        style={{ backgroundImage: `url(${image})` }}
      />
      <div className={styles.text}>
        <Markdown className={styles.title} content={title} />
        <Markdown className={styles.body} content={body} />
        <Button size="sm" variant="primary" to={buttonUrl}>
          {buttonText}
        </Button>
      </div>
    </div>
  </div>
)
