import React, { FunctionComponent } from "react"
import Image from "gatsby-image"
import classNames from "classnames"
import { navigate } from "gatsby"
import { getFluidGatsbyImage } from "@utils/gatsbyImage"
import styles from "@components/product/product.module.scss"
import { useShopifyProduct } from "@hooks/useShopifyProduct"
import { ProductDetails } from "@features/store/store-types"
import Skeleton from "@components/skeleton/skeleton"

interface SimpleProductProps {
  product: ProductDetails
  url?: string
  className?: string
}

export const SimpleProduct: FunctionComponent<SimpleProductProps> = ({
  product,
  url,
  className
}) => {
  const handle = product?.handle

  const { isLoading, error, data } = useShopifyProduct(handle)

  if (error) {
    return null
  }

  const productClassName = classNames(
    styles.product,
    styles.sm,
    styles.shadow,
    url && styles.interactive,
    className
  )

  const image = data?.product?.variants?.[0]?.image?.originalSrc

  const [titleFromTitleString] = product?.title?.split?.("—") || []

  const handleClick = (): void => {
    if (url) {
      navigate(url)
    }
  }

  const fluid = getFluidGatsbyImage(image || "", { maxWidth: 600 })

  return isLoading ? (
    <Skeleton />
  ) : (
    <div
      data-testid={`Product:${handle}`}
      className={productClassName}
      onClick={handleClick}
    >
      <div className={styles.cover}>
        {fluid ? (
          <Image fluid={fluid} />
        ) : (
          <div className={styles.imagePlaceholder} />
        )}
      </div>
      <h2 className={styles.title}>{titleFromTitleString}</h2>
    </div>
  )
}
