import React, { FunctionComponent } from "react"
import { Teacher } from "../../about-types"
import styles from "./people-list.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface PersonProps {
  teacher: Teacher
  htmlTitle?: boolean
}

export const Person: FunctionComponent<PersonProps> = ({
  teacher,
  htmlTitle = false
}) => (
  <div className={styles.person}>
    {htmlTitle ? (
      <Markdown className={styles.name} content={teacher.name} />
    ) : (
      <span className={styles.name}>{teacher.name}</span>
    )}
    <Markdown className={styles.info} content={teacher.bio || ""} />
  </div>
)
