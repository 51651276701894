import React, { FunctionComponent } from "react"
import styles from "./history.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface HistoryProps {
  ourHistory: string
}

export const History: FunctionComponent<HistoryProps> = ({ ourHistory }) => (
  <div id="history" className={styles.history}>
    <div className={styles.content}>
      <div className={styles.title}>Our History</div>
      <Markdown className={styles.body} content={ourHistory} />
    </div>
  </div>
)
