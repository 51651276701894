import React, { Fragment, FunctionComponent } from "react"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { Founder } from "../../about-types"
import { FounderProducts } from "./founder-products"
import styles from "./founder.module.scss"
import { FeaturedResources } from "@components/featured-resources/featured-resources"
import { Button } from "@components/button/button"
import { Markdown } from "@components/markdown/markdown"
import { Link } from "@components/link/link"

export type FounderVariant = "green" | "white"

export interface FounderDetailsProps {
  details: Founder
  variant?: FounderVariant
  className?: string
}

export const FounderDetails: FunctionComponent<FounderDetailsProps> = ({
  details,
  variant = "green",
  className
}) => (
  <div className={classNames(styles.founder, styles[variant], className)}>
    <div className={styles.content}>
      <div className={styles.image}>
        <Link to="/learn/teachers/rc-sproul">
          <img data-src={details.image} alt="" className="lazyload" />
        </Link>
      </div>
      <div className={styles.text}>
        <Link to="/learn/teachers/rc-sproul">
          <Markdown className={styles.name} content={details.name} />
        </Link>
        <Markdown className={styles.info} content={details.info} />
        <Button
          size="xs"
          variant="secondary"
          className={styles.buttonMore}
          to={details.url}
        >
          <FormattedMessage id="about-founder-more-button" />
        </Button>
      </div>
    </div>
    {details.resources && (
      <div className={styles.resources}>
        <div className={styles.header}>
          <span>
            <FormattedMessage id="New to R.C.? Start Here" />
          </span>
        </div>
        <FeaturedResources
          className={styles.featuredResources}
          labelCollapse
          list={details.resources}
        />
      </div>
    )}
    {details.products && details.products.length > 0 && (
      <Fragment>
        <div className={styles.products}>
          <div className={styles.header}>
            <span>
              <FormattedMessage id="about-founder-works" />
            </span>
          </div>
        </div>
        <FounderProducts items={details.products.slice(0, 4)} />
      </Fragment>
    )}
  </div>
)
