import { createClient, EntryCollection } from "contentful"
import { itemCount, readTime } from "../../core/utils"
import {
  ContentfulScriptureReference,
  getTranslateableScripture,
  mapScriptureReference
} from "../../features/scriptures/mappers/scripture-reference-mapper"
import { TranslateableString } from "../../types/intl"
import { IndexPageKey } from "../../types/page"
import { Resource, ResourceType } from "../../types/resource"
import { ListItem, REQUIRED_LIST_LENGTH } from "./featured-resources"
import { FeaturedResourceEntry, IndexPageEntry } from "./types"

export const mapFeaturedResourceItem = (
  item: FeaturedResourceEntry,
  type: IndexPageKey
): Resource => {
  let time = 0
  let image = ""
  let teacher = ""
  let avatar = ""
  let datePublished = undefined
  let typeForIcon: ResourceType = "article"

  if (item.typeForIcon) {
    typeForIcon = item.typeForIcon
  } else if (item.hasVideo === true) {
    typeForIcon = "video"
  } else if (type === "sermons") {
    typeForIcon = "sermon"
  } else if (type === "devotionals") {
    typeForIcon = "devotional"
  } else if (type === "collections") {
    typeForIcon = "collection"
  }

  if (item.datePublished) {
    datePublished = Date.parse(item.datePublished)
  }

  if (item.bodyCopy) {
    time = readTime(item.bodyCopy)
  } else if (item.answer) {
    time = readTime(item.answer.childMarkdownRemark?.html)
  }

  if (item.headerImage) {
    image = item.headerImage?.fields?.file.url || ""
  } else if (item.image) {
    image = item.image?.fields?.file.url || ""
  }

  if (item.teachers && item.teachers.length) {
    const firstTeacher = item.teachers[0].fields

    teacher = `${firstTeacher.shortName}`

    if (firstTeacher.image) {
      avatar = firstTeacher.image?.fields?.file.url || ""
    }
  }

  let subtitle: TranslateableString = teacher || item.subtitle || ""

  if (item.collectionItems) {
    subtitle = itemCount(item.collectionItems)
  }

  if (
    (type === "devotionals" || type === "sermons") &&
    item.primaryScriptureReference &&
    item.primaryScriptureReference.start?.book
  ) {
    subtitle = getTranslateableScripture(
      mapScriptureReference(
        item.primaryScriptureReference as ContentfulScriptureReference
      )
    )
    avatar = ""
  }

  return {
    title: item.name || item.title || item.sermonTitle || item.question || "",
    slug: item.slug,
    datePublished,
    time: type === "devotionals" ? undefined : time,
    avatar,
    subtitle,
    image,
    typeForIcon
  }
}

export const fetchFeaturedResources = async (
  key: IndexPageKey
): Promise<ListItem[]> => {
  const accessToken = process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN
  const space = process.env.GATSBY_CONTENTFUL_SPACE_ID
  const environment = process.env.GATSBY_CONTENTFUL_ENVIRONMENT

  if (!accessToken || !space || !environment) {
    return []
  }

  const client = createClient({ accessToken, space, environment })

  const data: EntryCollection<IndexPageEntry> = await client.getEntries({
    content_type: "indexPage",
    "fields.key": key,
    include: 2
  })

  if (
    data.items.length !== 1 ||
    data.items[0].fields.featuredResources.length !== REQUIRED_LIST_LENGTH
  ) {
    return []
  }

  const sourceFeaturedResources = data.items[0].fields.featuredResources

  const featuredResources = sourceFeaturedResources.map((item) =>
    mapFeaturedResourceItem(item.fields, key)
  )

  return featuredResources
}
