import React, { FunctionComponent } from "react"
import { IndexPageHeader } from "../../types/page"
import styles from "./index-header.module.scss"

export interface IndexHeaderProps {
  header: IndexPageHeader
}

export const IndexHeader: FunctionComponent<IndexHeaderProps> = ({
  header: { subtitle, title, image },
  children
}) => (
  <>
    <header className={styles.indexHeader}>
      <div className={styles.indexHeaderContent}>
        <h1>{title}</h1>
        {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
      </div>
      {children}
    </header>
    {image && (
      <div className={styles.imageSegment}>
        <img data-src={image} alt="" className="lazyload" />
      </div>
    )}
  </>
)
