import React, { FunctionComponent } from "react"
import { IndexHeader } from "../../../../components/index-header/index-header"
import { WhoWeAreDetails, Teacher } from "../../about-types"
import { FounderDetails } from "../founder/founder"
import { PeopleList } from "../people-list/people-list"
import { History } from "../history/history"
import { PromoBlock } from "../promo-block/promo-block"
import styles from "./who-we-are-page.module.scss"

export interface WhoWeArePageProps {
  details: WhoWeAreDetails
  teachingFellowship: Teacher[]
}

export const WhoWeArePage: FunctionComponent<WhoWeArePageProps> = ({
  details,
  teachingFellowship
}) => {
  return (
    <div className={styles.whoWeAre}>
      <IndexHeader header={details.header} />
      <FounderDetails details={details.founder} variant="white" />
      <PeopleList
        {...details.teachingFellowship}
        teachers={teachingFellowship}
      />
      <PeopleList {...details.leadership} htmlTitle disableLinks />
      <PeopleList {...details.directors} small htmlTitle />
      <History ourHistory={details.ourHistory} />
      <PromoBlock
        title={details.promo.title}
        image={details.promo.image}
        body={details.promo.body}
        buttonText="Careers"
        buttonUrl={details.promo.url}
      />
    </div>
  )
}
