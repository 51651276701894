import React, { FunctionComponent } from "react"
import classNames from "classnames"
import { Avatar } from "../../../../components/avatar/avatar"
import { Link } from "../../../../components/link/link"
import { Teacher } from "../../about-types"
import styles from "./teaching-fellowship.module.scss"
import { Markdown } from "@components/markdown/markdown"

export interface TeacherProps {
  teacher: Teacher
  htmlTitle?: boolean
  className?: string
  disableLink?: boolean
}

export const TeacherView: FunctionComponent<TeacherProps> = ({
  teacher,
  htmlTitle = false,
  className,
  disableLink
}) => {
  const innerElements = (
    <>
      <Avatar size="xl" image={teacher.image} />
      {htmlTitle ? (
        <Markdown className={styles.name} content={teacher.name} />
      ) : (
        <div className={styles.name}>{teacher.name}</div>
      )}
      {teacher.bio && <Markdown className={styles.bio} content={teacher.bio} />}
    </>
  )

  if (disableLink) {
    return (
      <div className={classNames(styles.teacher, className)}>
        {innerElements}
      </div>
    )
  }

  return (
    <Link to={teacher.url} className={classNames(styles.teacher, className)}>
      {innerElements}
    </Link>
  )
}
